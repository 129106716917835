import React, { lazy } from 'react'

const ContentWithImageSlice = lazy(() => import('@slices/content-with-image'))
const HeadlineSlice = lazy(() => import('@slices/headline'))
const ContentSlice = lazy(() => import('@slices/content'))
const FullscreenImageSlice = lazy(() => import('@slices/fullscreen-image'))
const FullscreenVideoSlice = lazy(() => import('@slices/fullscreen-video'))
const GallerySlice = lazy(() => import('@slices/gallery'))
const TitleSlice = lazy(() => import('@slices/title'))
const VideoEmbedSlice = lazy(() => import('@slices/video-embed'))
const CustomHTML = lazy(() => import('@slices/custom-html'))
const TwoColumnWithImageSlice = lazy(
  () => import('@slices/two-columns-with-image'),
)

type SliceProps = {
  allSlices: any
  pageContext?: any
  theme?: any
}

export const Slice: React.FC<SliceProps> = ({
  allSlices,
  theme,
  pageContext,
}) =>
  allSlices
    ? allSlices.map((slice: any, index: number) => {
        switch (slice?.slice_type) {
          case 'fullscreen_image':
            return (
              <FullscreenImageSlice
                isFirst={index === 0}
                key={slice.id}
                props={slice}
              />
            )
          case 'fullscreen_video':
            return <FullscreenVideoSlice key={slice.id} props={slice} />
          case 'video_embed':
            return <VideoEmbedSlice key={slice.id} props={slice} />
          case 'content_with_image':
            return (
              <ContentWithImageSlice key={slice.id + index} props={slice} />
            )
          case 'headline':
            return <HeadlineSlice key={slice.id} props={slice} />
          case 'content':
            return (
              <ContentSlice
                className={index === 0 ? 'is-first-section' : 'not-first'}
                key={slice.id}
                props={slice}
              />
            )
          case 'gallery':
            return <GallerySlice key={slice.id} props={slice} />
          case 'title_':
            return (
              <TitleSlice
                key={slice.id}
                props={slice}
                pageContext={pageContext}
              />
            )
          case 'two_column_with_image':
            return (
              <TwoColumnWithImageSlice
                additionalClass={index === 0 ? 'is-first-section' : 'not-first'}
                key={slice.id}
                props={slice}
                pageContext={pageContext}
              />
            )
          case 'add_custom_embed_or_html_code':
            return <CustomHTML key={slice.id} props={slice} />
          // case 'contact_form':
          // return <ContactFormSlice key={slice.id} props={slice} />
          default:
            return null
        }
      })
    : null
